// Here you can add other styles
//Evoltec
// $Orange: #514ef3;
// $sidebar: #514ef3;
// $sidebarMenuColour: #fff;
// $scrolbarTrackOrange: rgba(227, 227, 252, 1);
// $sidebarScroll: $Orange;
// $buttonGradient: #a6a5f9;
// $boxShadow: rgba(111, 119, 240, 0.5);
// $shadow: rgba(0, 0, 0, 0.05);
// $cancelGray: #9da5b1;
// $containerBackground: rgba(246, 250, 253, 1);
// $stripedTableCell: rgba(246, 250, 253, 1);
// $breadcrumb-strip-color: white;
// $header-strip-color: rgba(246, 250, 253, 1);
// $scrollbarOrange: #514ef3;
// $tableContainerBackground: rgba(246, 250, 253, 1);
// $tableContainerBorderRadius: 0 0 0 0;
// $formContainerBackground: rgba(246, 250, 253, 1);
// $paddingContainer: 1rem;
// $formContainerCardBody: white;
// $notificationUnreadColor: rgba(81, 78, 243, 0.1);
// $loginImage: url('../assets/images/EvoltecLoginImage.png');
// $loginImageLargeScreen: url('../assets/images/Evoltec-login-logo-lg.svg');

//Askre
$Orange: #FF6600;
$sidebar: #FFFFFF;
$sidebarMenuColour: #868686;
$scrolbarTrackOrange: rgba(255, 203, 128, 0.5);
$sidebarScroll: lighten($Orange, 20%);
$buttonGradient: #feb47b;
$boxShadow: rgba(134, 134, 134, 0.4);
$shadow: rgba(0, 0, 0, 0.05);
$cancelGray: #a5a0a0;
$containerBackground: #FAF3EE;
$stripedTableCell: #FEEADC;
$breadcrumb-strip-color: #FAF3EE;
$header-strip-color: white;
$scrollbarOrange: #FF6600;
$tableContainerBackground: #FAF3EE;
$tableContainerBorderRadius: 1rem 1rem 0 0;
$formContainerBackground: white;
$paddingContainer: 0;
$formContainerCardBody: #FAF3EE;
$notificationUnreadColor: rgba(255, 102, 0, 0.1);
$loginImage: url('../assets/images/AskreLoginImage.png');
$loginImageLargeScreen: url('../assets/images/Askre-Logo-Login-lg.svg');

$DarkerOrange: darken($Orange, 5%);
$lighterOrange: lighten($Orange, 7%);
$veryLighterOrange: lighten($Orange, 20%);


//Evoltec

// :root {
//   --Orange: #514ef3;
//   $sidebar: #ffffff;
//   --DarkerOrange: darken(var(--Orange), 5%);
//   --lighterOrange: lighten(var(--Orange), 25%);
//   --header-bg-color: rgba(246, 250, 253, 1);
// }

//Askre

// :root {
//   --Orange: #FF6600;
//   $sidebar: #ffffff;
//   --DarkerOrange: darken(var(--Orange), 5%);
//   --lighterOrange: lighten(var(--Orange), 25%);
//   --header-bg-color: #FAF3EE;
// }