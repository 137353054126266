@import '_custom.scss';

@mixin button-style($isGradient) {   
    margin: 0.5em;
    font-size: small;
    white-space: nowrap;
    // border: 1px solid $Orange;
    border: none;
    box-shadow: 0 4px 6px $boxShadow;
    transition: background 0.3s ease, transform 0.1s ease;
  
    @if $isGradient {
      // background: linear-gradient(to right, $Orange, $buttonGradient);
      background: linear-gradient(to right, rgba(255, 102, 0, 1), rgba(255, 203, 128, 1));
      color: white;
  
      &:hover {
        background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
        color: white;
      }
      &:disabled {
        background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 203, 128, 1));
        color: $Orange !important;
      }
    } @else {
      background-color: $Orange;
      border-radius: 2rem !important;
      color: white;
  
      &:hover {
        color: white;
        background-color: $DarkerOrange;
        border-radius: 2rem !important;
      }
    }
  }

@mixin button($isGradient) {
        color: white;
        white-space: nowrap;

        @if $isGradient {
            background: linear-gradient(to right, rgba(255, 102, 0, 1), rgba(255, 203, 128, 1));
        
            // &:hover {
            //   background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
            //   border-color: $Orange;
            // }
          } @else {
            background-color: $Orange;
            // border-color: $Orange;
        
            &:hover {
              background-color: $DarkerOrange;
              color: white;
            }
          }
}


@mixin tab-style($isGradient) {
  white-space: nowrap;
  border: none;

  @if $isGradient {
    background: linear-gradient(to right, rgba(255, 102, 0, 1), rgba(255, 203, 128, 1));
  } @else {
    color: white;
    background-color: $Orange;
  }
}


@mixin button-orange($isGradient) {
  color: white;
  white-space: nowrap;
  // border: 1px solid $Orange;
  border: none;
  width: 150px;
  height: 38px;
  min-width: fit-content;
  // box-shadow: 0 4px 6px $boxShadow;
  // transition: background 0.3s ease, transform 0.1s ease;

  @if $isGradient {
    background: linear-gradient(to right, rgba(255, 102, 0, 1), rgba(255, 203, 128, 1));

    &:hover {
      background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
      color: white;
    }
  } @else {
    background-color: $Orange;

    &:hover {
      background-color: $DarkerOrange;
      color: white;
    }
  }
}


@mixin mainmaster-selected($isGradient) {
  color: white;
  white-space: nowrap;
  // border: 1px solid $Orange;
  border: none;
  border-radius: 50px;
  height: 30px;
  align-items: center;
  display: flex;
  // box-shadow: 0 4px 6px $boxShadow;
  // transition: background 0.3s ease, transform 0.1s ease;

  @if $isGradient {
    background: linear-gradient(to right, rgba(255, 102, 0, 1), rgba(255, 203, 128, 1));

    &:hover {
      background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
    }
  } @else {
    background-color: $Orange;
    border-color: $Orange;

    &:hover {
      background-color: $DarkerOrange;
      border-color: $DarkerOrange;
    }
  }
}

@mixin calendar-buttons($isGradient) {
  color: white !important; /* Use !important if necessary */
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;

  @if $isGradient {
    background: linear-gradient(to right, $Orange, $buttonGradient);
    border-color: $Orange;

    &:hover {
      background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
      border-color: $DarkerOrange;
    }
  } @else {
    background-color: $Orange;
    border-color: $Orange;

    &:hover {
      background-color: $DarkerOrange;
      border-color: $DarkerOrange;
    }
  }
}

@mixin cancel-buttons($isGradient) {
  color: white;
  @if $isGradient {
    background: linear-gradient(to right, #a5a0a0, #d8d5d6);
    border-color: lightgrey;
  } @else {
    background-color: #9da5b1;
    border-color: #9da5b1;
  }
}

@mixin timer-buttons($isGradient) {
    width: 100px;
    // background-color: $Orange;
    // background: linear-gradient(to right, $Orange, $buttonGradient);
    // border-color: $Orange;
    color: white;
    margin-right: 10px;
    @if $isGradient {
      background: linear-gradient(to right, $Orange, $buttonGradient);
      border-color: $Orange;
      &:disabled {
        background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 203, 128, 1));
        color: $Orange !important;
      }
    } @else {
      background-color: $Orange;
      border-color: $Orange;
    }
}

@mixin sidebar-fontweight($isGradient) {
  @if $isGradient {
    font-weight: 500;
  } @else {
    font-weight: var(--cui-nav-link-font-weight);
  }
}

@mixin ccard-form-padding($isGradient) {
  @if $isGradient {
    margin: 15px !important;
    padding: 10px !important;
    background-color: white;
    box-shadow: 0 4px 6px $shadow;
  } @else {
    margin: 0px;
    padding: 0px;
    background-color: white;
    box-shadow: none;
  }
}