body {
  background-color: var(--cui-tertiary-bg);
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl('padding-right', var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.header > .container-fluid,
.sidebar-header {
  min-height: calc(
    4rem + 1px
  ); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(
      4rem + 1px
    ); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl('margin-right', auto);
  }
}

.header > .container-fluid + .container-fluid {
  min-height: 3rem;
}

.footer {
  min-height: calc(
    3rem + 1px
  ); // stylelint-disable-line function-disallowed-list
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: var(--cui-tertiary-bg);
    }

    .footer {
      --cui-footer-bg: var(--cui-body-bg);
    }

    .ccard-padding,
    .wrapper,
    .container,
    div {
      background-color: var(--cui-tertiary-bg);
    }

    input::placeholder {
      color: rgb(158, 157, 157); /* Replace with your desired color */
      opacity: 1; /* Optional: adjust opacity if needed */
    }

    .nav-tabs {
      --cui-nav-tabs-border-color: white !important;
      --cui-nav-tabs-link-active-border-color: white;
    }

    .nav-tabs .nav-link {
      color: white;
    }

  }
}
