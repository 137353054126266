@import '_custom.scss';
@import 'mixins.scss';

@font-face {
  font-family: 'PlusJakartaSans';
  src: local('PlusJakartaSans'), url(../assets/fonts/PlusJakartaSans-Light.otf) format('woff');
}

@font-face {
  font-family: 'PlusJakartaSansBold';
  src: local('PlusJakartaSansBold'), url(../assets/fonts/PlusJakartaSans-Medium.otf) format('woff');
}

* {
  font-family: 'PlusJakartaSans';
}

body {
  overflow: auto;
  // overflow-x: hidden;
  // overflow-y: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media print {
  body * {
    visibility: hidden;
  }

  .barcode-popup,
  .barcode-popup * {
    visibility: visible;
  }

  .barcode-popup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  #barcode-container,
  #barcode-container * {
    visibility: visible;
  }

  #barcode-container {
    position: center;
    padding-left: 150px;
    width: 400px;
    height: 400px;
  }

  .print-button {
    display: none;
  }
}

.sidebar-nav {
  .nav-link.active {
    color: $Orange !important;
    background: var(--cui-sidebar-nav-link-active-bg);
  }
  // .mainmaster-field.selected {
  //   @include button-style($Orange != #514ef3);
  // }
  .nav-link {
    color: $sidebarMenuColour;
    // font-weight: 500;
    // color: #868686;
    // color: #fff;

    .nav-icon {
      color: $sidebarMenuColour;
      // color: #868686;
      // color: #fff;
    }

    &.active {
      .nav-icon {
        color: $Orange;
      }
    }
    &:hover {
      .nav-icon {
        // color: $sidebar;
        color: $Orange;
      }
    }
  }

  .nav-link {
    @include sidebar-fontweight($Orange != #514ef3);
  }
}

.sidebar-nav .nav-group .nav-group-items {
  margin-left: 19px;
  margin-top: 5px;
}

.sidebar-nav .nav-group.show .nav-group-toggle {
  // color: rgb(243 245 249 / 95%);
  color: $sidebarMenuColour;
}

.nav-item {
  margin-bottom: 10px;
}

.signinform {
  width: 100%;
  line-height: 2.5;
  border: 1px solid #c7c7c7;
}

.signin-div{
  margin: auto;
  width: 25rem;
}
// .passwordlog {
//     width: 85%;
// }

// @media (min-width: 2200px) {
//   .signin-div{
//     width: 45rem;
//   }
// }

@media (min-width: 1441px) and (max-width: 2199px) {
  .signin-div{
    width: 25rem;
  }
}

@media (min-width: 2200px) {
  .signin-div{
    width: 35rem;
  }
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .signin-div{
    width: 20rem;
  }
}

.submitbtn {
  width: 100%;
  background-color: $Orange !important;
  color: #fff !important;
  border-radius: 10px;
}
// .logincard_body{
//   width: 125%;
// }
.eyeforpassword {
  width: 86%;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  width: 100%; /* Set width to 100% */
  // height: 100%;
  max-width: none; /* Remove max-width limitation */
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: auto; /* Center align horizontally */
  margin-right: auto; /* Center align horizontally */
  background-color: $formContainerBackground;
  // background-color: white;
}

.signinportion {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .first-column {
    display: none;
  }
  .second-column {
    width: 100%;
    // background-image: url('../assets/images/Logo\ and\ Rectangle.png');
    background-image: $loginImage;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:100vh;
  }
  .passwordlog {
    width: 100%;
}
.signin-div{
  width: 65%;
}
.signinblock{
  border-radius: 10px !important;
}
.footer-login{
  color: white !important;
}
}
@media (max-width: 465px) {
  .signin-div{
    width: 100%;
  }
}

.role-master {
  .table {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  th {
    background-color: #dedee1 !important;
    height: 45px !important;
  }
  td {
    height: 45px !important;
  }
  table {
    padding: 0px !important;
  }
}

.table {
  > :not(caption) {
    > * > * {
      padding: 0.3rem 2.3rem !important;
    }
  }
}

.supercompleted {
  .css-a643pv-MuiStack-root {
    padding-top: 0px;
  }
}

.reassignpopup,
.reassignpopup1 {
  .modal-content {
    width: 50rem !important;
  }
}

.box-layout {
  border: 1px solid #cccccc;
  padding: 20px;
  background-color: #ffffff;
  width: 55rem;
  /* Adjust width as needed */
  height: 20rem;
  margin: 10px auto;
  /* Center the box horizontally */
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Add a subtle box shadow */
}

.button-container {
  // margin-top: 20px; /* Add some space between the box and buttons */
  text-align: center;
  /* Center align the buttons */
}

.box-header {
  margin-bottom: 20px;
}

.close-button {
  color: blue !important;
  /* Change close button icon color */
}

.emailCampaignpopup,
.emailCampaignpopup1 {
  .modal-content {
    width: 50rem !important;
  }
}

.image-container {
  width: 100%;
  height: 200px;
  // width: 236px;
  // height: 236px;
  background-color: #eaeef4;
  border-radius: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rightLog {
  width: 491px;
  height: 596px;
  background-color: #eaeef4;
  padding: 20px;
}

.editActivitypopup {
  .modal-content {
    width: 27rem !important;
    left: 45px !important;
    height: 35rem;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
  }
}

.react-select__menu {
  overflow-y: hidden !important;
  /* Hide vertical scrollbar */
}

/* Ensure that the options list remains scrollable */
.react-select__menu-list {
  max-height: 200px;
  /* Set a max height to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.notificationpopup .modal-dialog {
  width: 448px !important;
  height: 486px;
  /* left: auto !important; */
  top: 19px;
  border-radius: 8px;
  margin-right: 18px;
  float: right;
  background-color: #f3f4f7;
}

.notificationCard {
  padding: 10px 0px;
}

.notificationCard.card-body {
  background-color: white;
}

// .event-styles {}

.fc .fc-button-primary {
  background-color: $DarkerOrange !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active .fc-button-hover {
  background-color: white;
  border-color: $DarkerOrange !important;
  color: $DarkerOrange;
}

.fc .fc-toolbar-title {
  color: black;
}

.fc-h-event .fc-event-main-frame {
  height: 80px;
}

.fc-h-event {
  border: none;
}

.fc-h-event .fc-event-title-container {
  background-color: $DarkerOrange;
}

.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.fc-day-top {
  text-align: center;
}

.fc-day-top > .fc-day-number {
  float: none !important;
}

.fc-daygrid-day-number {
  color: black !important;
}

.fc-col-header-cell-cushion {
  color: black !important;
}

.confirmationpopup,
.reassignpopup,
.reassignpopup1 {
  .modal-content {
    width: 30rem;

    .popup-btn {
      display: flex;
      justify-content: center;

      button {
        width: 25% !important;
        display: flex !important;
        justify-content: center !important;
      }
    }

    .primary_btn {
      margin-right: 1rem;
    }

    .popup-text {
      display: flex;
      justify-content: center;
      // padding: 30px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .modal-content {
      width: 100%; /* Full width for mobile screens */
    }

    .popup-btn {
      button {
        width: 100%;
        display: block;
        margin-top: 1rem;
      }
    }

    .primary_btn {
      margin-right: 1rem !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .modal-content {
      width: 100%; /* Adjust width for tablet screens */

      .popup-btn {
        button {
          width: 100%;
          display: block;
          margin-top: 1rem;
        }
      }

      .primary_btn {
        margin-right: 1rem !important;
      }
    }
  }
}

.reassignpopup1 {
  .modal-content {
    width: 50rem;
    left: -165px;
  }
}

.emailCampaignpopup1 {
  .modal-content {
    width: 50rem;
    left: -165px;
  }
}

@media screen and (max-width: 651px) {
  .notificationpopup {
    .modal-dialog {
      width: 306px !important;
      font-size: 12px;
    }
  }
}

// .confirmationpopupdate,
// .reassignpopup,
// .editActivitypopup,
// .reassignpopup1 {
//   .modal-content {
//     width: 50rem;
//     left: -241px;

//     .popup-btn {
//       display: flex;
//       justify-content: center;

//       button {
//         width: 25% !important;
//         display: flex !important;
//         justify-content: center !important;
//       }
//     }

//     .primary_btn {
//       margin-right: 1rem;
//     }

//     .popup-text {
//       display: flex;
//       justify-content: center;
//       padding: 30px;
//     }

//     .css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar {
//       grid-column: 1/4;
//       grid-row: 3;
//       display: none;
//     }

//     .MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.css-1vskg8q {
//       display: none;
//     }

//     .css-1hbyad5-MuiTypography-root {
//       display: none;
//     }

//     .css-11a8txn-MuiStack-root> :not(style):not(style) {
//       font-size: 25px;
//     }
//   }
// }

.confirmationpopupdate,
.emailCampaignpopup,
.editActivitypopup,
.emailCampaignpopup1 {
  .modal-content {
    width: 50rem;
    left: -241px;

    .popup-btn {
      display: flex;
      justify-content: center;

      button {
        width: 25% !important;
        display: flex !important;
        justify-content: center !important;
      }
    }

    .primary_btn {
      margin-right: 1rem;
    }

    .popup-text {
      display: flex;
      justify-content: center;
      padding: 30px;
    }

    .css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar {
      grid-column: 1/4;
      grid-row: 3;
      display: none;
    }

    .MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.css-1vskg8q {
      display: none;
    }

    .css-1hbyad5-MuiTypography-root {
      display: none;
    }

    .css-11a8txn-MuiStack-root > :not(style):not(style) {
      font-size: 25px;
    }
  }

  /* Media query for tablets */
  @media (min-width: 768px) and (max-width: 1024px) {
    .modal-content {
      width: 40rem; /* Adjust width for tablets */
      max-width: calc(
        100vw - 20px
      ); /* Ensure modal doesn't exceed viewport width */
      left: 50%; /* Center modal horizontally */
      transform: translateX(-50%); /* Adjust for centering */
    }

    .popup-btn {
      button {
        width: 100%; /* Full width for buttons on tablets */
        margin-top: 1rem; /* Add margin between buttons */
      }
    }

    .css-11a8txn-MuiStack-root > :not(style):not(style) {
      font-size: 15px;
    }
  }

  /* Media query for mobile screens */
  @media screen and (max-width: 576px) {
    .modal-content {
      width: calc(100vw - 30px); /* Full width with padding */
      max-width: 100%; /* Ensure modal doesn't exceed viewport width */
      left: 50%; /* Center modal horizontally */
      transform: translateX(-50%); /* Adjust for centering */
    }

    .popup-btn {
      button {
        width: 100%; /* Full width for buttons on mobile */
        margin-top: 1rem; /* Add margin between buttons */
      }
    }

    .css-11a8txn-MuiStack-root > :not(style):not(style) {
      font-size: 15px;
    }
  }
}

.forgot-pswrd {
  margin-top: -21px;
}

// .divider-vertical {
//   border-left: 1px solid #ccc;
//   height: 30rem;
//   margin: 0 15;
// }

.profile-popup {
  padding: 40px;

  .avator_size {
    size: 50px;
    height: 100%;
    object-fit: contain;
  }

  .avator-profile {
    display: flex;
    justify-content: center !important;
  }
}

.avator-profile {
  position: relative;

  .avatar-img {
    height: 100%;
    width: 100%;
  }
}

.procard {
  height: 48rem;
}

.avatar-img {
  height: 100%;
  width: 100%;
}

.camera-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  /* Set background color if needed */
  border-radius: 50%;
  padding: 4px;
  /* Adjust padding as needed */
}

.camera-icon {
  width: 20px;
  /* Adjust the size of the camera icon */
  height: 20px;
}

.camicon {
  background-color: $Orange;
}

.fixed-avatar-size {
  width: 10rem;
  height: 10rem !important;
  object-fit: cover;
  background-color: #e4e5e7;
  box-shadow: 0 0 0 3px #e4e5e7;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.img {
  width: 100%;
  height: 100%;
}

.add-btn {
  background-color: $Orange;
  color: #fff;
  border-radius: 2rem;
}

.mainmaster-field {
  font-size: 16px !important;
  line-height: 52px !important;
  margin-bottom: 7px;
}

// .master-card {
//   height: 45rem;
// }

.mainmaster-field {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 30px;
  display: flex;
  white-space: nowrap;
  // justify-content: center;
}

// .mainmaster-field.selected {
//   // background-color: $Orange;
//   color: #fff;
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;
//   display: flex;
//   // justify-content: center;
//   border-radius: 50px;
//   height: 30px;
//   align-items: center;
//   white-space: nowrap;
// }

.mainmaster-field.selected {
  @include button-style($Orange != #514ef3);
}

.mastericon {
  display: flex;
  // justify-content: center;
}

.nav-item {
  margin-bottom: 1px;
}

.card {
  border-radius: 0px;
  // background-color: $containerBackground;
  // border-radius: 1rem 1rem 0px 0px;
  // border-bottom: none;
}

.sidebar-nav .nav-group-toggle::after {
  background-color: white !important;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-color: $Orange !important;
  }

  .sidebar-nav .nav-link:hover {
    color: $Orange;
  }

  .sidebar-nav .nav-link:hover {
    color: $Orange !important;
  }

  .sidebar-nav .nav-icon:hover {
    color: $Orange;
  }
}

.master-container {
  height: 35rem;
  overflow: scroll;

  th {
    width: 100px;
    white-space: normal;
  }

  .MuiInputBase-root {
    padding: 0.1px 14px;
    height: 2.5rem;
  }

  input {
    text-align: 'left';
  }

  table thead tr th {
    // background-color: #f6fafd;
    background-color: $containerBackground;
    color: #7e92a2;
    height: 50.59px;
  }
  tbody tr:nth-child(even) {
    background-color: $stripedTableCell;
    // background-color: white;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
    // background-color: $stripedTableCell;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: $stripedTableCell !important;
    --cui-table-bg-type: none;
    --cui-table-color-type: none;
  }
}

.master-container::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

.master-container::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}

.master-container::-webkit-scrollbar-thumb {
  background: $scrollbarOrange;
  border-radius: 10px;
}

.master-container::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}

.nav-item {
  margin-bottom: 1px;
}

// .btn-style {
//   // background-color: $Orange;
//   color: white;
//   border-radius: 2rem !important;
//   margin: 0.5em;
//   font-size: small;
//   white-space: nowrap;
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;

//   &:hover {
//     background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
//     color: white;
//   }
// }

.btn-style {
  @include button-style(
    $Orange != #514ef3
  ); // Use gradient if $Orange is not the specific color
}

// .btn-style:hover {
//   background-color: $DarkerOrange;
//   color: white;
// }

.btn-style:disabled {
  background-color: $Orange;
  color: white;
}

th {
  white-space: nowrap;
  object-fit: contain;
  font-family: var(--cui-body-font-family);
  font-weight: 500;
}

.dropdown-toggle {
  border-color: var(--cui-border-color);
  border-width: var(--cui-border-width);
}

.select-style {
  border-radius: 3rem;
  // width: unset;
}

.select-data {
  border-radius: 3rem;
  width: 90%;
}

.icon-btn-style {
  background-color: transparent;
  border-color: transparent;
}
.icon-btn-style:disabled {
  background-color: transparent !important;
  border-color: transparent;
  color: darkgray !important;
}

.togglebutn {
  // align-items: center;
  display: flex;

  // justify-content: center;
  .form-check-input:checked {
    background-color: $Orange;
    border-color: $Orange;
  }
}

.font-style {
  white-space: nowrap;
  object-fit: contain;
}

.icon-style {
  color: #e01414;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.addunitmaster-footer {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footerButton .d-flex {
  flex-wrap: wrap;
  justify-content: center;
}

.footerButton .unit-masbtn {
  margin: 0.5em; /* Add some spacing between buttons */
}

.footerButton .btn-orange {
  margin: 0.5em; /* Add some spacing between buttons */
}

@media (max-width: 576px) {
  /* Mobile screens */
  .footerButton .unit-masbtn,
  .footerButton .btn-orange {
    // width: 100%;
    box-sizing: border-box;
  }
}

.unit-masbtn {
  margin: 1px 60px 0px 0px;
  width: 150px;
  height: 38px;
}

.addicon {
  color: '#c7c7c7' !important;
}

.input-feedback {
  color: red;
  // text-transform: capitalize;
  text-align: left !important;
  word-break: break-word;
  font-size: 14px;
}

.form-control {
  border: 1px solid #80808040;
  // background-color: inherit;
  // --cui-secondary-color: rgba(37, 43, 54, 0.681);
  --cui-secondary-color: rgba(0, 0, 0, 0.4);
}

textarea.form-control {
  border: 1px solid #80808040;
  --cui-secondary-color: rgba(0, 0, 0, 0.4);
}

// .form-control:focus {
//   border: 1.5px solid #acabeb !important;
//   // --cui-secondary-color: rgba(37, 43, 54, 0.681);
//   --cui-secondary-color: rgba(0, 0, 0, 0.4);
//   transition: none;
//   box-shadow: none;
// }

// .form-select:focus {
//   border: 1.5px solid #acabeb !important;
//   // --cui-secondary-color: rgba(37, 43, 54, 0.681);
//   --cui-secondary-color: rgba(0, 0, 0, 0.4);
//   transition: none;
//   box-shadow: none;
// }

.form-control_one {
  border: 1px solid red !important;
}

textarea.form-control_one {
  border: 1px solid red !important;
}


.form-select {
  border: 1px solid #80808040;
}

.form-select_one {
  border: 1px solid red;
}

.was-validated .form-control:invalid {
  background-image: none;
}

.was-validated .form-control:valid {
  background-image: none;
  border-color: #dbdfe6;
}

.was-validated .form-select:invalid:not([multiple]):not([size]) {
  --cui-form-select-bg-icon: none;
}

.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: none;
  border-color: #dbdfe6;
}

input[type='date']:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}

.text-danger {
  color: #e01414;
  font-weight: bold;
  padding: 0px 5px;
}

.sticky-cell {
  // position: sticky;
  // right: 0;
  display: 'flex';
  height: '30px';
}

.dropdown-style {
  .toggleBorder {
    border: none;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle::before {
    content: none;
  }

  .btn:disabled, .btn.disabled {
    background-color: white;
    color: gray;
    border-color: gray;
  }
}

.modal-footer {
  justify-content: center !important;
}

.calendar-element {
  border: solid;
  border-width: var(--cui-border-width);
  border-radius: var(--cui-border-radius);
  border-color: var(--cui-border-color);
  width: 100%;
  z-index: 1;

  .rdrMonthAndYearWrapper {
    border: solid;
    border-color: var(--cui-border-color);
    border-radius: var(--cui-border-radius);
    border-width: var(--cui-border-width);
    background-color: $Orange;
    height: 50px;
  }

  .superbtn {
    margin-right: 10px;
    margin-top: 0;
  }

  .rdrMonth {
    width: auto;
  }

  .rdrDayToday {
    color: $Orange;
  }

  // .rdrCalendarWrapper {
  //   display: inline-block;
  // }
}

.rdrCalendarWrapper .rdrDateRangeWrapper {
  display: inline-block;
  width: 100%;
}

.date-range-element {
  .rdrMonthAndYearWrapper {
    background-color: rgb(239, 242, 247);
    border-radius: var(--cui-border-radius);
    // border-width: var(--cui-border-width);
    // border: solid;
    // border-color: var(--cui-border-color);
    height: 40px;
    padding-top: none;
  }

  .rdrDateDisplayWrapper {
    // background-color: rgb(61, 145, 255);
    background-color: var(--cui-border-color);
    border-radius: var(--cui-border-radius);
  }

  .rdrMonths {
    border: solid;
    border-radius: var(--cui-border-radius);
    border-width: var(--cui-border-width);
    color: var(--cui-border-color);
    width: 100%;
  }

  .rdrDayToday .rdrInRange .rdrStartEdge .rdrEndEdge {
    color: $Orange(81, 78, 243) !important;
    // color: #FF6600
  }

  .rdrInRange {
    color: $Orange(81, 78, 243) !important;
    // color: #FF6600
  }

  .rdrStartEdge {
    color: $Orange(81, 78, 243) !important;
    // color: #FF6600
  }

  .rdrEndEdge {
    color: $Orange(81, 78, 243) !important;
    // color: #FF6600
  }

  .rdrDateDisplayItemActive {
    border-color: $Orange(81, 78, 243) !important;
    // color: #FF6600
  }
}

.compact-table .CTableHeaderCell,
.compact-table .CTableDataCell {
  padding: 4px 8px; /* Adjust as needed */
}

.table-headers-dropdown {
  .dropdown-menu.show {
    display: flex;
  }

  // .dropdown-menu{
  //  top: -60px;
  // }
}

.divider-horizontal {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  /* Adjust the margin as needed */
}

.divider-vertical {
  border-left: 1px solid #ccc;
  height: 100%;
  /* Adjust the height as needed */
  margin-left: 25px;
  /* Adjust the margin as needed */
}

.datepick {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.436rem !important;
  }

  .css-4jnixx-MuiStack-root {
    padding-top: 0px;
  }

  .MuiTextField-root {
    width: -webkit-fill-available;
  }
}

.checkbox {
  padding-left: 1.3rem;
}

.vertical-line {
  width: 4.333333%;
}

.table-master {
  // width: 70%;
  .table > tbody {
    vertical-align: middle;
  }
}

.table-head1 {
  align-items: center;
  display: flex;
}

.add-task-btn {
  color: black;
  border: solid;
  border-width: var(--cui-border-width);
  border-color: var(--cui-border-color);
  background-color: white;
  border-radius: 2rem;

  .svg {
    vertical-align: bottom;
  }
}

.wo-status-closed {
  color: green;
}

.wo-status-open {
  color: red;
}

.wo-status-progress {
  color: rgba(237, 208, 28);
}

.add-forms {
  padding: 0;
}

.title-strip {
  padding: 1em;
  background-color: $Orange;
  color: white;
}

.border-end {
  background-color: $sidebar;
  border-right: none !important;
}

.calendar-input {
  .css-a643pv-MuiStack-root {
    padding: 0%;
    overflow: unset;
  }

  .complient-action {
    border: none;
  }

  .assigntaskbtn {
    margin-top: 2rem !important;
  }
}

.mutiselect {
  .css-10ywtup-MuiFormControl-root {
    margin: 0px;
  }
}

// .date-picker-form {
//   .MuiStack-root {
//     padding-top: 0%;
//     overflow: unset;
//   }

//   .MuiOutlinedInput-root {
//     &:hover {
//       border-color: var(--cui-focus-ring-color);
//     }
//     &:focus fieldset {
//       border-color: var(--cui-border-color);
//     }
//     &:hover fieldset {
//       border-color: var(--cui-focus-ring-color);
//       transition: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     }
//   }
//   .Mui-focused .MuiOutlinedInput-notchedOutline {
//     border-color: var(--cui-focus-ring-color);
//     border-width: var(--cui-focus-ring-width);
//   }

//   .MuiInputBase-root-MuiOutlinedInput-root {
//     // height: 37px;
//     border-radius: var(--cui-border-radius);
//     border-color: var(--cui-border-color);
//   }

//   .MuiFormControl-root-MuiTextField-root {
//     height: 38px,
//   }

//   .MuiOutlinedInput-notchedOutline {
//     // height: 37px;
//     border-radius: var(--cui-border-radius);
//   }

//   .MuiStack-root>.MuiTextField-root {
//     min-width: 0px !important;
//   }
// }
.date-picker-form {
  .MuiStack-root {
    padding-top: 0;
    overflow: unset;
  }

  .MuiTextField-root {
    min-width: 0px !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
  }

  .MuiOutlinedInput-root {
    &:hover {
      border-color: var(--cui-focus-ring-color);
    }
    &:focus fieldset {
      border-color: var(--cui-border-color);
    }
    &:hover fieldset {
      border-color: var(--cui-focus-ring-color);
      transition:
        0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--cui-focus-ring-color);
    border-width: var(--cui-focus-ring-width);
  }

  .MuiFormControl-root-MuiTextField-root {
    height: 38px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: var(--cui-border-radius);
  }
}

.file-upload-toggle.dropdown-toggle::after {
  content: none;
}

.date-picker-community {
  .MuiStack-root {
    padding-top: 0%;
    overflow: unset;
  }

  .MuiTextField-root {
    min-width: 0px !important;
  }

  .MuiTextField-root {
    width: 100%;
    border-color: var(--cui-border-color) !important;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    // border-color: red;
    border-width: var(--cui-focus-ring-width);
    border-color: var(--cui-focus-ring-color);
  }

  .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: var(--cui-border-radius);
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    height: 38px !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    // height: 37px;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
  }
}

.date-picker-filter {
  .MuiStack-root {
    padding-top: 0%;
    overflow: unset;
  }

  .MuiTextField-root {
    min-width: 0px !important;
  }

  .MuiTextField-root {
    width: 100%;
    border-color: var(--cui-border-color) !important;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    // border-color: red;
    border-width: var(--cui-focus-ring-width);
    border-color: var(--cui-focus-ring-color);
  }

  .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 3rem;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    height: 38px !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    // height: 37px;
    border-radius: 3rem;
    border-color: var(--cui-border-color);
  }
}
.date-picker-filter .MuiTextField-root .MuiOutlinedInput-root {
  background-color: white !important;
  border-radius: 3rem;
}

.date-picker-error {
  .css-4jnixx-MuiStack-root {
    padding-top: 0px;
    overflow: unset;
  }

  .MuiTextField-root {
    min-width: 0px !important;
    width: 100%;
    border-color: red;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    // border-color: red;
    border-width: var(--cui-border-width);
    border-color: red;
  }

  .MuiOutlinedInput-root:hover fieldset {
    border-color: red;
    // transition: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 37px;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: red;
  }
}

.focusepro {
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline:focus {
    border-color: #3432be;
    /* Border color when focused */
    border-width: 2px;
    /* Border width when focused */
  }
}

.css-160unip {
  padding-top: 0px !important;
}

.css-froe0x {
  padding-top: 0px !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  vertical-align: middle !important;
  text-align: start !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
  vertical-align: text-bottom;
}

.nav-pills .nav-link.active {
  @include tab-style($Orange != #514ef3);
}

.nav-pills .nav-link {
  color:  rgba(9, 44, 76, 1);
}

// .nav-tabs .nav-link.active {
//   @include tab-style($Orange != #514ef3);
// }

.nav-tabs .nav-link {
  color:  rgba(9, 44, 76, 1);
}


// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   // background-color: $Orange;
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;
//   color: white;
// }

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   @include tab-style($Orange != #514ef3);
// }

// .nav-tabs .nav-link.active,
// .nav-tabs .show > .nav-link {
//   background-color: white;
//   color: white;
//   // color: rgba(9, 44, 76, 1) !important;
// }

// .nav-tabs .nav-link {
//   color: rgba(9, 44, 76, 1) !important;
// }

// .nav-pills .nav-link {
//   color: rgba(9, 44, 76, 1) !important;
// }
// .nav-tabs .nav-link.active,
// .nav-tabs .show > .nav-link {
//   @include tab-style($Orange != #514ef3); // Use gradient if $Orange is not the specific color
// }

.css-vubbuv {
  width: 0.8em !important;
  height: 0.8em !important;
  vertical-align: text-bottom;
}

// .myDatePicker {
//   .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
//     // border-color: red;
//     border-width: var(--cui-focus-ring-width);
//     border-color: var(--cui-focus-ring-color);
//   }
// }

.MuiSelect-root::before {
  content: 'Select Status';
  /* Specify your placeholder text */
  color: #aaa;
  /* Adjust the color of the placeholder text */
  font-size: medium;
  /* Adjust the font size of the placeholder text */
}

.MuiSelect-select.MuiSelect-selectMenuOpen::before {
  content: none;
  /* Hide the placeholder text when the menu is open */
}

.icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
  width: 1rem;
  height: 1rem;
  font-size: 1.25rem;
  vertical-align: baseline;
}

.role-name {
  display: flex;
  justify-content: center;
}

.role-filed {
  width: 25rem;
  margin: 25px;
}

.btn_component {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: 500;
}

.form-label {
  margin-bottom: 0%;
  margin: 6px 0px;
}

.btn-light {
  height: 41.6px !important;
  width: 41.6px !important;
}

.task-details {
  color: #999;
  /* Grey color */
}

//file upload button
.uploadButton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.uploadButton:hover {
  background-color: #45a049;
}

.fileInput {
  display: none;
}

.profilecollum {
  width: 22%;
}

.leads-buttons {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-end;
}

// .leads-buttons  {
//   margin-bottom: 10px; /* Adjust margin as needed */
// }
.activitytableHeader {
  color: #9ea5a5;
}

.activitytableHeaderCol1 {
  color: $Orange;
}

// .timmer-button {
//   width: 100px;
//   // background-color: $Orange;
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;
//   color: white;
//   margin-right: 10px;
// }

.countrycode > div {
  width: 50%;
}

.countrycodeprofile > div {
  width: 50%;
  font-size: 15px;

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 88px;
  }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1lk8wd-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.countrycode > div > div,
.countrycodeprofile > div > div {
  border-color: #dbdfe6 !important;
  border-radius: 6px 0px 0px 6px !important;
  border-right: none;
}

// .css-b62m3t-container{

// }
.css-13cymwt-control {
  border-color: #dbdfe6 !important;
  border-radius: 6px 0px 0px 6px !important;
  border-right: none;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-t3ipsp-control:active,
.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus {
  border-color: #dbdfe6 !important;
  box-shadow: 0 0 0 0.25rem rgba(88, 86, 214, 0.25) !important;
}

.table-selectdown {
  height: 35rem;
  overflow: auto;
  // border-left: 1px solid #ccc;
  // background-color: #f6fafd;
  background-color: $stripedTableCell;
  padding-top: 15px;  
}

.table-selectdown::-webkit-scrollbar,
.table-selectdown::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

.table-selectdown::-webkit-scrollbar-track,
.table-selectdown::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}

.table-selectdown::-webkit-scrollbar-thumb,
.table-selectdown::-webkit-scrollbar-thumb {
  background: $scrollbarOrange;
  border-radius: 20px;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .countrycode > div {
    width: 25%;
  }

  .countrycodeprofile > div {
    width: 57%;
  }

  .start-buttons-align {
    justify-content: left;
  }

  .buttons-align {
    justify-content: end;
  }
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .buttons-align {
    justify-content: end;
  }
}

@media screen and (max-width: 768px) {
  .popup-btn {
    button {
      width: 100%;
      // display: block;
      margin-top: 1rem;
    }
  }

  .primary_btn {
    margin-right: 0 !important;
  }

  .verticalRuler {
    display: block;
  }

  .btn_component {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .btn_component {
    .unit-masbtn {
      margin: 0px 0px 10px 0px;
    }
  }

  .role-filed {
    width: 10rem;
  }

  .css-b62m3t-container {
    width: 29%;
  }

  .reassignpopup1 {
    .modal-content {
      width: 37rem !important;
      left: -62px;
    }
  }

  .emailCampaignpopup {
    .modal-content {
      width: 37rem !important;
      left: -62px;
    }
  }
}

@media (min-width: 1440px) and (max-width: 2560px) {
  .submaster-autocomplete {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .role-filed {
    width: 19rem;
  }

  .css-b62m3t-container {
    width: 24%;
  }

  .table-selectdown {
    border-left: none;
    // height: auto !important;
    // overflow: hidden !important;
    padding-bottom: 10px;
  }

  .submaster-autocomplete {
    .MuiInputBase-root {
      width: 200px !important;
      margin-right: 0px;
    }
    .MuiOutlinedInput-root {
      font-size: 14px;
    }
    display: flex;
    justify-content: center;
  }

  .btn-style {
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  }

  .buttons-align {
    justify-content: end;
  }

  .start-buttons-align {
    justify-content: left;
  }

  .countrycode > div {
    width: 57%;
    // font-size: 11px;
  }

  .countrycodeprofile > div {
    width: 72%;
    font-size: 12px;
  }

  .profile-popup {
    padding: 8px;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .countrycode > div {
    width: 64%;
  }

  .countrycodeprofile > div {
    width: 57%;
  }
}

@media (min-width: 321px) and (max-width: 376px) {
  .countrycode > div {
    width: 59%;
    font-size: 13px;
  }

  .countrycodeprofile > div {
    width: 69%;
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .countrycode > div {
    width: 57%;
    font-size: 11px;
  }

  .countrycodeprofile > div {
    width: 72%;
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .role-filed {
    width: 9rem;
    margin: 6px;
  }

  .css-b62m3t-container {
    width: 50%;
  }

  .table-selectdown {
    border-left: none;
    // height: auto !important;
    // overflow: hidden !important;
    padding-bottom: 10px;
    display: flex;
  }

  // .table-selectdown::-webkit-scrollbar {
  //   display: none;
  // }

  // .mainmaster-field {
  //   background-color: rgb(8, 10, 12, 0.05);
  //   margin-right: 0.3rem;
  //   border-color: #dbdfe6;
  // }

  .submaster-autocomplete {
    .MuiInputBase-root {
      width: 150px !important;
      margin-right: 0px;
    }
    .MuiOutlinedInput-root {
      font-size: 14px;
    }
    display: flex;
    justify-content: right;
    padding-top: 10px;
  }

  .btn-style {
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  }

  .buttons-align {
    justify-content: center;
  }

  .start-buttons-align {
    justify-content: center;
  }

  .table-main-master {
    flex-direction: column-reverse;
  }

  .profile-popup {
    padding: 8px;
  }

  /* Additional rule for screens less than 374px */
  @media (max-width: 374px) {
    .btn-style {
      margin: 0.2rem; /* Set margin to 0 for buttons */
    }
  }
}

.task-form-date {
  .css-a643pv-MuiStack-root {
    padding-top: 0px;
  }

  .css-a643pv-MuiStack-root {
    height: 1.3em;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--cui-border-color);
    height: 20px;
    border-radius: var(--cui-border-radius);
  }
}

.add-task-form-date {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--cui-border-color);
    height: 20px;
    border-radius: var(--cui-border-radius);
  }
}

.estimated_date_time_picker {
  .MuiStack-root {
    overflow: 'unset';
    // padding: 0,
    padding-top: '0px';
  }
}

.css-1lk8wd-MuiAutocomplete-root .MuiAutocomplete-input {
  text-overflow: initial !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-n1mmuz-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 54px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 6px 0 0 6px !important;
}

.phone-error {
  border-radius: 5px;
  border: 1px solid red;
}

.Techniciantab {
  .table > :not(caption) > * > * {
    padding: 4px 1rem !important;
  }
}

@media (max-width: 368px) {
  .btn-style {
    display: flex;
    background-color: $Orange;
  }
}

.batchstyl {
  position: relative;
  top: -15px;
  font-size: 10px;
  left: -7px;
}

.phone-disabled {
  .react-tel-input .form-control {
    background-color: var(--cui-secondary-bg);
  }
}

.react-tel-input .form-control {
  height: 37.6px !important;
}

.error-message {
  color: red;
  /* or any other desired color */
  font-size: 12px;
}

.custom-autocomplete {

  & .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
    padding: 0px 4px 2.5px 8px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: var(--cui-border-color);
    // height: '-webkit-fill-available';
    height: 42px;
    background-color: white;
    z-index: -1;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
    z-index: 2;
  }

  & .MuiAutocomplete-endAdornment {
    z-index: 2;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }

}

.custom-autocomplete-status {
  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: var(--cui-border-color);
    height: 42px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
    width: 150px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiInputBase-root {
    width: 150px;
  }
}

.custom-autocomplete-error {
  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: red;
    height: 42px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: red;
    border-width: var(--cui-border-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }

  // & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
  //   border-color: red;
  // }
}

.custom-autocomplete-dropdown {
  & .MuiInputBase-root {
    height: 37px;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);

    &:hover {
      border-color: var(--cui-focus-ring-color);
    }
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.2rem;
  }

  & .MuiOutlinedInput-root {
    padding-top: 0px;

    // & fieldset {
    //   border-color: ${error.property ? "red" : "var(--cui-border-color)"};
    // }
    &.Mui-focused fieldset {
      border-color: var(--cui-border-color);
      border-width: var(--cui-focus-ring-width);
    }

    &:hover fieldset {
      border-color: var(--cui-focus-ring-color);
      transition:
        0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }

  & .MuiAutocomplete-inputFocused {
    border-color: var(--cui-border-color);
  }
}


.custom-autocomplete-chip {

  & .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
    padding: 0px 4px 2.5px 8px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: var(--cui-border-color);
    height: '-webkit-fill-available';
    background-color: white;
    z-index: -1;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
    z-index: 2;
  }

  & .MuiAutocomplete-endAdornment {
    z-index: 2;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }

}

.css-ef2gac {
  border: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 1px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.css-1moqe4s {
  border: 0px solid #000 !important;
}

.radio-button-checked {
  .form-check-input:checked[type='radio'] {
    background-color: $Orange;
    border-color: $Orange;
  }
}

.cancel-button {
  color: white;
}

.checkbox-orange {
  .form-check-input:checked {
    background-color: $Orange;
    border-color: $Orange;
  }
}

// .access-rights-checkbox-orange {
  .form-check-input:checked {
    background-color: $Orange;
    border-color: $Orange;
  }
// }

.css-e3uf1q {
  padding: 0px !important;
}

.css-ef2gac {
  border: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 1px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.css-1moqe4s {
  border: 0px solid #000 !important;
  // border-radius: 3em 3em 3em 3em;
  height: auto !important;
}

// .btn-orange {
//   // background-color: $Orange;
//   color: white;
//   white-space: nowrap;
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;

//   &:hover {
//     background: linear-gradient(to right, $DarkerOrange, darken($buttonGradient, 10%));
//     color: white;
//   }
// }

.btn-orange {
  @include button-orange(
    $Orange != #514ef3
  ); // Use gradient if $Orange is not the specific color
}


// .btn-orange:hover {
//   background-color: $DarkerOrange;
//   color: white;
// }

.checkbox-orange {
  .form-check-input:checked {
    background-color: $Orange;
    border-color: $Orange;
  }
}
.btn-green {
  background-color: green;
  color: white;
  border-radius: 25px; 
}

.btn-red {
  background-color: red;
  color: white;
  border-radius: 25px; 
}

.radio-button-checked {
  .form-check-input:checked[type='radio'] {
    background-color: $Orange;
    border-color: $Orange;
  }
}

.cancel-button {
  color: white;
}

.notification-card.unread:hover {
  // background: rgb(110, 109, 184) !important;
  background: $veryLighterOrange !important;
}

.notification-card {
  border-radius: 5px;
}

.notification-card.unread {
  border-left: 5px solid $Orange;
  background-color: $notificationUnreadColor;
}

.notification-card.read {
  border-left: 1px solid #080a0c2d;
  // background-color: #e0dbdb;
}

.notification-card.unread .CCardBody {
  background-color: #ebe8e8;
}

.notification-title {
  font-size: 22px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  color: rgba(9, 44, 76, 1);
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  height: 55px;
  background: #ffffff !important;
  border-left: 4px solid $DarkerOrange;
  // border-right: 4px solid $DarkerOrange;
  color: #000 !important;
  border-radius: 5px;
}

.scroll-Content {
  overflow-y: hidden;
  height: 61vh;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
}

.css-kcmh8k {
  border: 0px solid #f8efef !important;
  border-radius: 2em 4em 2em 2em;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
  margin: 0.5em 1em 0.5em 1em;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  margin: 0.5em 1em 0.5em 1em;
}

.css-1vs67w3-MuiTypography-root {
  width: 100% !important;
  margin-top: 0.5em !important;
}

.form-data {
  padding-left: 2em;
  padding-right: em;
}

// .calendar-task {
//   width: 80%;
//   display: flex;
// }

.priority-data {
  border: 1px solid black;
}

.css-o4b71y-MuiAccordionSummary-content {
  margin: 0px !important;
}

.table-dropdown {
  background-color: $Orange;
  color: white;
  border-radius: 2rem;
  font-size: small;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.version {
  justify-content: flex-start;
  font-size: 12px;
  display: flex;
  margin-left: 7px;
}

.file-upload-error {
  border-color: red;
}

.workflow-hedder {
  align-items: center;
}

.newworkflow {
  position: relative;
  top: 177px;
}

.addworkorder {
  border: 1px solid #959597;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
}

.cardstyle {
  padding: 15px;
  border-radius: 13px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 150px;
}

.card-row {
  height: 100%;
  align-items: center;
}

@media (max-width: 767px) {
  .workcard1 {
    width: 70%;
  }

  .workcard2 {
    width: 30%;
  }
}

.custom-autocomplete-filter {
  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: var(--cui-border-color);
    height: 42px;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: 3rem;
    border-color: var(--cui-border-color);
    width: 90%;
    background-color: white;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }
}

.progressbar {
  margin-bottom: 1em;
}
.setup-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6fafd;
  border-radius: 8px;
  box-shadow: #000;
  box-shadow: 3px 5px 10px rgb(204, 202, 202);
}
.setup-cardboady {
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

/* YourStyles.css */
.right-corner {
  text-align: right;
  /* Ensure text is aligned to the right */
}

@media (max-width: 768px) {
  .right-corner {
    text-align: left;
    /* Align text to the left on smaller screens */
  }
}

.btn_access {
  margin-bottom: 5px;
  margin-left: 25px;
}

.usermaster-card {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: $tableContainerBackground;
  border-radius: 5px;
  // height: 34rem;
  // height: 100%;
  height: 500px;
}

@media (min-width: 768px) and (max-width: 1100px) {
  .usermaster-card {
    height: 360px !important;
  }
  .usermaster-card1 {
    height: 360px !important;
  }
  .usermaster-card2 {
    height: 15rem !important;
  }

  .user-rights-card-body {
    height: 72vh;
  }
}

@media (min-width: 1101px) and (max-width: 1439px) {
  .usermaster-card {
    height: 500px !important;
  }
  .usermaster-card1 {
    height: 500px !important;
  }
  .usermaster-card2 {
    height: 23rem !important;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  .usermaster-card {
    height: 460px !important;
  }
  .usermaster-card1 {
    height: 460px !important;
  }

  .usermaster-card2 {
    height: 21rem !important;
  }
}

@media (min-width: 2001px)  and (max-width: 2560px){
  .usermaster-card {
    height: 900px !important;
  }
  .usermaster-card1 {
    height: 900px !important;
  }
  .accessrights-scroll {
    height: 750px;
  }

  .usermaster-card2 {
    height: 48rem !important;
  }
}

.usermaster-card1 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  // height: 100%;
  height: 500px;
}

.usermaster-card2 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: $tableContainerBackground;
  border-radius: 5px;
  // height: auto;
  // min-height: 20rem;
  padding: 11px;
  // height: 21rem !important;
}

.access-rights {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.access-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.access-right p {
  margin: 0;
  flex-grow: 1;
}

.access-right img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

/* Responsive design */
@media (max-width: 600px) {
  .access-rights {
    padding: 10px;
  }

  .access-right {
    flex-direction: column;
    align-items: flex-start;
  }

  .access-right img {
    margin-left: 0;
    margin-top: 5px;
  }
}

.Access-mapping {
  // height: 34rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.user-select {
  cursor: pointer;
  background-color: $Orange;
  color: white !important;
  // padding: 5px;
  padding: 2px 1px 2px 1px;
  border-radius: 5px;
}

.user-notselect {
  cursor: pointer;
  background-color: transparent;
}

// .dropdown-menu {
//   // --cui-dropdown-link-active-bg: #FF6600;
//   --cui-dropdown-link-active-bg: #514ef3;
// }

.dropdown-item:active {
  background-color: $Orange;
  color: #ffffff;
}

// .custom-calendar .fc-prev-button {
//   background-color: $Orange;
//   color: #ffffff;
//   border-color: $Orange;
// }

// .custom-calendar .fc-next-button {
//   background-color: $Orange;
//   color: #ffffff;
//   border-color: $Orange;
// }

.spinner-color {
  color: $Orange;
}

// .calendar-popup {
//   display: flex;
//   justify-content: space-between;
//   background-color:  $Orange;
//   border-radius: 2em 2em 0em 0em;
// }
// .css-1qdrhs7-MuiTreeItem-content .MuiTreeItem-iconContainer svg {
//   font-size: 18px;
//   color: #3733ed;
// }

.MuiTreeItem-iconContainer svg{
  color: $Orange;
  font-size: 18px;
}

@media (max-width: 1440px) {
  .mainmaster-field {
    font-size: 14px !important;
    line-height: 52px !important;
    margin-bottom: 7px;
  }

  .h4 {
    font-size: 18px;
  }
}
.text-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.text-editor .ql-container.ql-snow {
  flex: 1;
  overflow-y: auto;
}

.css-a643pv-MuiStack-root {
  padding-top: 0px !important;
}


#leasingCustomerTable.MuiTableContainer-root,
#leasingPropertyTable.MuiTableContainer-root {
    background-color: $containerBackground;
}

#leasingCustomerTable .table-striped > tbody > tr:nth-of-type(odd) > *,
#leasingPropertyTable .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #fff;
  // background-color: $stripedTableCell !important;
  --cui-table-bg-type: none;
  --cui-table-color-type: none;
}

#leasingCustomerTable .table-striped > tbody > tr:nth-of-type(even) > *,
#leasingPropertyTable .table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: $stripedTableCell !important;
  --cui-table-bg-type: none;
  --cui-table-color-type: none;
}

#leasingPropertyTable .table thead tr th,
#leasingCustomerTable .table thead tr th {
  // background-color: #f6fafd;
  background-color: $containerBackground;
  color: #7e92a2;
  padding: 10px !important;
}

#leasingPropertyTable tbody tr td,
#leasingCustomerTable tbody tr td {
  padding: 10px !important;
  // min-height: calc(100vh - 20vh) !important;
}

// #leasingCustomerTable {
//   padding: 10px !important;
//   min-height: calc(100vh - 37vh) !important;
// }

// #leasingPropertyTable .table > :not(caption) > * > * {
//   padding: 10px 0px !important;
// }

// #leasingPropertyTable tbody tr:nth-child(even),
// #leasingCustomerTable tbody tr:nth-child(even) {
//   // background-color: #f6fafd;
//   background-color: $containerBackground;
// }

// #leasingPropertyTable tbody tr:nth-child(odd),
// #leasingCustomerTable tbody tr:nth-child(odd) {
//   background-color: #fff;
//   // background-color: $containerBackground;
// }

#leasingPropertyTable::-webkit-scrollbar,
#leasingCustomerTable::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

#leasingPropertyTable::-webkit-scrollbar-track,
#leasingCustomerTable::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}

#leasingPropertyTable::-webkit-scrollbar-thumb,
#leasingCustomerTable::-webkit-scrollbar-thumb {
  background: $scrollbarOrange;
  border-radius: 20px;
}

#leasingPropertyTable::-webkit-scrollbar-thumb:hover,
#leasingCustomerTable::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}

.MuiClock-pin,
// .MuiMenuItem-root.Mui-selected,
.MuiMultiSectionDigitalClockSection-item.Mui-selected,
.MuiPickersDay-root.Mui-selected,
.MuiClockPointer-root,
.MuiTabs-indicator {
  background-color: $Orange !important;
}

.MuiClockPointer-thumb {
  background-color: $Orange !important;
  border-color: $Orange !important;
}

.MuiTab-root.Mui-selected {
  color: $Orange !important;
}

.MuiOutlinedInput-root.Mui-focused {
  border-color: var(--cui-border-color) !important;
}

.accordian-service {
  flex-shrink: 0;
  padding: 0.5em 1.5em 0.5em 1.5em;
  font-size: 1em;
  font-weight: 400;
  border: $lighterOrange;
  // background-color: $lighterOrange;
  background-color: #f9f7ff;
  border-radius: 0.5em;
  text-align: left;
}

.calendar-input-fields {
  .form-control:disabled {
    color: black;
    opacity: 0.5;
    // font-weight: 400;
  }
}

.calendar-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $Orange;
  // background-color: $lighterOrange;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.scroll-content {
  padding: 1.5em;
  overflow-y: auto;
  height: 60vh;
  // border-radius: 2rem;
}

.calendar-task {
  display: flex;
  align-items: center;
  width: 80%;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

// .fc .fc-toolbar .fc-button.fc-prev-button,
// .fc .fc-toolbar .fc-button.fc-next-button,
// .fc .fc-toolbar .fc-button.fc-today-button,
// .fc .fc-toolbar .fc-button.fc-dayGridMonth-button {
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;
//   color: white !important; /* Use !important if necessary */
//   padding: 0.5em 1em;
//   border-radius: 5px;
//   font-size: 14px;
//   cursor: pointer;
//   margin: 5px;
// }

.fc .fc-toolbar .fc-button.fc-prev-button,
.fc .fc-toolbar .fc-button.fc-next-button,
.fc .fc-toolbar .fc-button.fc-today-button,
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button {
  @include calendar-buttons($Orange != #514ef3);
}

// .fc .fc-toolbar .fc-button.fc-prev-button:hover,
// .fc .fc-toolbar .fc-button.fc-next-button:hover,
// .fc .fc-toolbar .fc-button.fc-today-button:hover,
// .fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover {
//   background: linear-gradient(to right, $Orange, $buttonGradient);
//   border-color: $Orange;
// }

.fc .fc-toolbar .fc-button.fc-prev-button:hover,
.fc .fc-toolbar .fc-button.fc-next-button:hover,
.fc .fc-toolbar .fc-button.fc-today-button:hover,
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover {
  @include calendar-buttons($Orange != #514ef3);
}

.fc .fc-toolbar .fc-button.fc-prev-button:focus,
.fc .fc-toolbar .fc-button.fc-next-button:focus,
.fc .fc-toolbar .fc-button.fc-today-button:focus,
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus {
  outline: none;
  box-shadow: 0 0 2px 2px $boxShadow;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button {
  display: none;
}

.notification-popup-tab {
  .nav-pills .header-nav .nav-link.active {
    color: white;
  }
}

.notificationCard::-webkit-scrollbar {
  display: none;
}

.form-group-roles {
  & .MuiFormGroup-root {
    display: flex;
    flex-direction: row !important;
  }
}

.password-visibility-icon {
  .MuiIconButton-root {
    border-top-right-radius: var(--cui-border-radius);
    border-bottom-right-radius: var(--cui-border-radius);
  }
}

/* Apply 38vh for small screens */
@media (min-width: 320px) and (max-width: 576px) {
  .signinportion {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .signinblock {
    // width: 90%;

    .card-body {
      padding: 0;
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
  }

  .bg-white {
    height: 100vh;
  }
}

//------------------------media responsive-----------------//

/* Apply 34vh for extra small screens */
@media (max-width: 375px) {
  /* Adjust max-width as needed for extra small */
  .custom-height {
    height: 34vh !important; /* Use !important to override inline styles */
  }
}

/* Apply 38vh for small screens */
@media (min-width: 376px) and (max-width: 576px) {
  .custom-height {
    height: 38vh !important; /* Use !important to override inline styles */
  }
}

@media (max-width: 768px) {
  .custom-height {
    background-size: contain; /* Use cover for smaller screens */
    // background-position: center;
  }
}

@media (max-width: 576px) {
  /* Extra small devices */
  body {
    font-size: calc(
      var(--cui-body-font-size) * 0.85
    ); /* Adjust size as needed */
  }
  .dropdown-item {
    font-size: calc(var(--cui-body-font-size) * 0.85);
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  /* Tablet devices */
  body {
    font-size: calc(
      var(--cui-body-font-size) * 0.9
    ); /* Adjust size as needed */
  }
  .dropdown-item {
    font-size: calc(var(--cui-body-font-size) * 0.85);
  }
  .customercare-icon {
    width: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Tablet devices */
  body {
    font-size: calc(
      var(--cui-body-font-size) * 0.9
    ); /* Adjust size as needed */
  }
  .dropdown-item {
    font-size: calc(var(--cui-body-font-size) * 0.9);
  }
  .customercare-icon {
    width: 20px;
  }
}

.customercare-icon {
  width: 25px;
}

// @media (min-width: 376px) and (max-width: 576px) {
//   .customercare-icon {
//     width: 50px !important;
//   }
// }

@media (min-width: 376px) and (max-width: 576px) {
  // .signinform {
  //   width: 75%;
  //   line-height: 1.5;
  //   border: 1px solid #c7c7c7;
  // }
  .signinform .MuiOutlinedInput-input {
    height: 0.5em; /* Adjust the height as needed */
  }
  .input-field-login {
    & .MuiOutlinedInput-input {
      height: 0.5em; /* Adjust the height as needed */
    }
    & .MuiTextField-root {
      width: 100%;
    }
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .signinblock {
    margin-left: 0;
  }
  .signinblock .mb-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .signinportion {
    justify-content: center;
  }
}

// @media (min-width: 375px) {
@media (max-width: 767px) {
  .signinform {
    width: 100%;
  }
  .forgot-signinform {
    width: 100%;
    height: 32px;
    border: 1px solid #c7c7c7;
  }
  .input-field-login {
    & .MuiOutlinedInput-input {
      height: 0.5em; /* Adjust the height as needed */
    }
    & .MuiTextField-root {
      width: 100%;
    }
  }
  .customercare-icon {
    width: 20px;
  }
  .calendar-icon {
    width: 22px;
  }
  .notification-icon {
    width: 22px;
  }
}

// @media (min-width: 320px) {
@media (max-width: 768px) {
  .signinform {
    width: 100%;
    line-height: 0;
  }
  .input-field-login {
    & .MuiOutlinedInput-input {
      height: 0.5em; /* Adjust the height as needed */
    }
    & .MuiTextField-root {
      width: 100%;
    }
  }
  .submitbtn {
    height: 32px;
  }
  .header-nav .nav-link {
    padding-left: 0.5rem;
  }
  .btn {
    font-size: calc(var(--cui-body-font-size) * 0.85);
  }
  .pagination-font {
    .MuiTypography-root {
      font-size: calc(var(--cui-body-font-size) * 0.75);
      font-family: var(--cui-body-font-family);
      font-weight: 400;
    }
    .MuiButtonBase-root.MuiPaginationItem-root {
      height: 25px;
      min-width: 25px;
    }
  }
  .form-control {
    font-size: calc(var(--cui-body-font-size) * 0.85);
  }
  .fc .fc-toolbar-title {
    color: black;
    font-size: 1.2em;
  }
  .fc .fc-button .fc-icon {
    font-size: 1.2em;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
.pagination-font {
  .MuiTypography-root {
    font-size: calc(var(--cui-body-font-size) * 0.75);
    font-family: var(--cui-body-font-family);
    font-weight: 400;
  }
  .MuiButtonBase-root.MuiPaginationItem-root {
    height: 25px;
    min-width: 25px;
  }
}
}

@media (min-width: 1200px) {
  .pagination-font {
    .MuiTypography-root {
      // font-size: calc(var(--cui-body-font-size) * 0.75);
      font-family: var(--cui-body-font-family);
      font-weight: 400;
    }
    .MuiButtonBase-root.MuiPaginationItem-root {
      height: 25px;
      min-width: 25px;
    }
  }
  }
@media (min-width: 1024px) and (max-width: 2560px) {
  .signinblock {
    // width: 90%;
    // ma rgin-left: 65px;

    .card-body {
      padding: 0;
    }
  }

  .customercare-icon {
    width: 20px;
  }
  .calendar-icon {
    width: 22px;
  }
  .notification-icon {
    width: 22px;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .px-4 {
    // padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btn {
    font-size: calc(var(--cui-body-font-size) * 0.85);
  }
  .reset-signinform {
    width: 100% !important;
    height: 41px !important;
    border: 1px solid #c7c7c7;
  }

  .forgot-signinform {
    width: 100% !important;
    // height: 32px !important;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
  }

  .signinform {
    width: 100%;
    height: 41px;
  }

  .superbtn {
    margin-right: 10px;
    margin-top: 10px;
  }

  .assign-row {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 577px) and (max-width: 768px){
  .forgot-signinform {
    width: 100%;
    height: 32px;
    // line-height: 2.5;
    border: 1px solid #c7c7c7;
  }
}

// .forgot-signinform {
//   width: 100%;
//   height: 54px;
//   // line-height: 2.5;
//   border: 1px solid #c7c7c7;
// }

.reset-signinform {
  width: 100%;
  // height: 54px;
  // line-height: 2.5;
  border: 1px solid #c7c7c7;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .customercare-icon {
    width: 20px;
  }
  .calendar-icon {
    width: 22px;
  }
  .notification-icon {
    width: 22px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .date-time-scheduler {
    .MuiDateTimePickerTabs-root,
    .MuiDateCalendar-root,
    .MuiPickersLayout-toolbar,
    .MuiPickersLayout-contentWrapper {
      width: 250px !important;
    }
    .MuiStack-root {
      overflow-x: hidden;
    }
    .MuiPickersToolbarText-root {
      font-size: 2rem;
    }

    .MuiDateTimePickerToolbar-separator {
      padding-top: 5px;
    }

    .MuiPickersLayout-root {
      justify-content: center;
    }

    padding-left: 0px;
    padding-right: 0px;
  }

  .image-container {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
  }

  .image-container img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .date-time-scheduler {
    .MuiDateTimePickerTabs-root,
    .MuiDateCalendar-root,
    .MuiPickersLayout-toolbar,
    .MuiPickersLayout-contentWrapper {
      width: 300px !important;
    }
    .MuiStack-root {
      overflow-x: hidden;
    }
    .MuiPickersToolbarText-root {
      font-size: 2rem;
    }

    .MuiDateTimePickerToolbar-separator {
      padding-top: 5px;
    }

    .MuiPickersLayout-root {
      justify-content: center;
    }

    padding-left: 0px;
    padding-right: 0px;
  }
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: $Orange;
}

@media (min-width: 320px) and (max-width: 576px) {
  .notification-modal-css {
    position: absolute;
    top: 35px;
    right: 25px;
    z-index: 9999;
    width: 220px;
    height: 400px;
    .MuiPaper-root {
      height: 100%;
    }
  }

  .image-container {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
  }

  .image-container img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 4404px) {
  .notification-modal-css {
    position: absolute;
    top: 35px;
    right: 20px;
    z-index: 9999;
    width: 300px;
    height: 400px;
    .MuiPaper-root {
      height: 100%;
    }
  }
}

@media (min-width: 1440px) and (max-width: 2560px) {
  .notification-modal-css {
    position: absolute;
    top: 35px;
    right: 20px;
    z-index: 9999;
    width: 350px;
    height: 400px;
    .MuiPaper-root {
      height: 100%;
    }
  }
}

.profile-avatar-bg {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2rem;
  background-color: $Orange;
  display: flex;
  justify-content: center;
  padding-top: 7px;
}

.profile-image-camera-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  color: white !important;
}

@media (min-width: 320px) and (max-width: 576px) {
  .side-menu-submaster {
    display: flex;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .image-modal-size .modal-dialog {
    width: 400px;
    max-width: none;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .image-modal-size .modal-dialog {
    max-width: none;
  }
  /* Specific width adjustments within this range */
  @media (max-width: 420px) {
    .image-modal-size .modal-dialog {
      width: 350px;
    }
  }

  @media (max-width: 320px) {
    .image-modal-size .modal-dialog {
      width: 300px;
    }
  }
}

@media (min-width: 2000px) {
  .image-modal-size .modal-dialog {
    width: 1500px;
    max-width: none;
  }
}

@media (min-width: 1440px) and (max-width: 1999px) {
  .image-modal-size .modal-dialog {
    width: 1000px;
    max-width: none;
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .image-modal-size .modal-dialog {
    width: 600px;
    max-width: none;
  }
}

/* Global styles or within your component's CSS-in-JS */

.attachment-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.attachment-container img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 20px;
  pointer-events: none;
  box-sizing: border-box;
  overflow: hidden;
}

.attachment-container:hover .overlay {
  opacity: 1;
}

@media (max-width: 768px) {
  .dialog-pop {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .dialog-pop {
    padding: 10px;
  }
  .overlay {
    font-size: 12px;
  }
}

.sidebar-nav-scroll::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
  border-radius: 20px;
}

.sidebar-nav-scroll::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

.sidebar-nav-scroll::-webkit-scrollbar-thumb {
  background: $sidebarScroll;
  border-radius: 20px;
}

.sidebar-nav-scroll::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}

.custom-padding {
  padding-top: 1em;
}

@media (min-width: 992px) and (max-width: 1923px) {
  .custom-padding {
    padding-top: 1.3em;
  }
}

@media (min-width: 1445px) and (max-width: 1923px) {
  .custom-padding {
    padding-top: 3em;
  }
}

@media (min-width: 1924px) {
  .custom-padding {
    padding-top: 3em;
  }
}

// .btn-secondary {
//   background: linear-gradient(to right, #a5a0a0, #d8d5d6);
//   color: white;
// }

#formDetailsModal  {
  padding: 200px 0px;
}


// .btn-secondary:hover {
//   background: linear-gradient(to right, #989393, #d8d5d6);
//   // background-color: #1e1c1d;
// }

.btn-secondary {
  @include cancel-buttons($cancelGray != #9da5b1);
  // @include calendar-buttons($Orange != #514ef3);
}

.timmer-button {
  @include timer-buttons(($Orange != #514ef3));
}

// .card-body {
//   background-color: $containerBackground;
//   border-radius: 1rem 1rem 0px 0px;
// }

.wrapper {
  background-color: white;
}

.flex-grow-1 { 
  background-color: $containerBackground;
}

.footer {
  background-color: $containerBackground;
}

.header {
  border-bottom: none;
}

.border-bottom {
  border-bottom: none;
}

.card-footer {
  border-top: none;
  background-color: inherit !important;
  padding: 0% !important;
}

.breadcrumb-background {
  background-color: $breadcrumb-strip-color;
}

.notif-card.card {
  border-radius: 0;
  // border-bottom: none;
}

.appheader-icon-strip {
  background-color: $header-strip-color;
}

// .table-card-body {
//   background-color: $tableContainerBackground;
//   border-radius: $tableContainerBorderRadius;
//   height: 100%;
//   overflow-y: scroll;
// }

.table-card {
  border: none;
}

// .form-card-body {
//   background-color: $formContainerCardBody;
//   height: 70vh;
//   overflow-y: auto;
// }

// .report-card-body {
//   background-color: $formContainerCardBody;
//   height: 70vh;
//   overflow-y: auto;
// }

.p-3 {
  padding: $paddingContainer !important;
}

.notification-card-padding.p-3 {
  padding: 1rem !important
}

.ccard-padding {
  @include ccard-form-padding($Orange != #514ef3);
}

.custom-min-vh {
  min-height: 80vh;
}



#propertyLeaseDetailsModal .btn-close {
  display: none !important;
}

#table-tag-label {
  background: #514ef3;
  margin: 3px;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
}

#leasing-details-table .table > :not(caption) > * > * {
  padding: 0px !important;
}

.lease-details-form-error {
  border: 1px solid #e55353;
  border-radius: 0.375rem;
  background-color: #fff
}
.notification-bar{
  height: 400px;
}
.custom-autocomplete-multiselect {
  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: var(--cui-border-color);
    // height: fit-content;
    // width: fit-content;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
    border-color: var(--cui-border-color);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }
}

.custom-autocomplete-multiselect-error {
  & .MuiAutocomplete-root .MuiOutlinedInput-root fieldset {
    border-color: red;
    // height: fit-content;
    // width: fit-content;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
    border-radius: var(--cui-border-radius);
    border-color: red;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--cui-border-color);
    border-width: var(--cui-focus-ring-width);
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--cui-focus-ring-color);
    transition:
      0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root input {
    padding-left: 0.75rem;
  }
}

.commission-background {
  background-color: $containerBackground;
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .table-height {
    // height: 27rem;
    height: 38rem;
    overflow: auto;
    position: relative;
  }

    .work-order-table {
      // height: 22rem;
      height: 35rem;
      overflow: auto;
      position: relative;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
  .table-height {
    // height: 66vh;
    height: 40rem;
    overflow: auto;
    position: relative;
  }
  .work-order-table {
    height: 40rem;
    overflow: auto;
    position: relative;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .table-height {
    // height: 66vh;
    height: 50rem;
    overflow: auto;
    position: relative;
  }

  .work-order-table {
    height: 45rem;
    overflow: auto;
    position: relative;
  }
}

@media (max-width: 425px) {
  .table-height {
    // height: 56vh;
    height: 52vh;
    overflow: auto;
    position: relative;
  }
  .work-order-table {
    height: 25rem;
    overflow: auto;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .table-height {
    // height: 66vh;
    height: 17rem;
    overflow: auto;
    position: relative;
  }
  .work-order-table {
    height: 25rem;
    overflow: auto;
    position: relative;
  }
}

@media (min-width: 1440px) and (max-width: 2441px) {
  .table-height {
    // height: 66vh;
    height: 63vh;
    overflow: auto;
    position: relative;
  }

  .work-order-table {
    height: 32rem;
    overflow: auto;
    position: relative;
  }
}

// @media (min-width: 1440px) and (max-width: 2441px) {
//   .table-height {
//     height: 79vh;
//     overflow: auto;
//   }
// }

@media (min-width: 2442px) and (max-width: 2560px) {
  .table-height {
    // height: 81vh;
    height: 72vh;
    overflow: auto;
    position: relative;
  }
  .work-order-table {
    height: 53rem;
    overflow: auto;
    position: relative;
  }
}

// @media (min-width: 426px) and (max-width: 768px) {
//   .table-height {
//     height: 66vh;
//     overflow: auto;
//   }
// }

.custom-row {
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 20px;
  margin: 20px;
}

.link-button {
  background: none;
  border: none;
  color: rgba(var(--cui-link-color-rgb), var(--cui-link-opacity, 1));
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}

.custom-table {
  width: 100%; 
  border-collapse: collapse; 
}

 .custom-table th,
  .custom-table td {
    padding: 10px 15px !important;
    text-align: left !important;
  }
  
  .custom-table .table-header {
    background-color: #f8f9fa !important;
    font-weight: bold !important;
  }

.custom-table .table-row-grey {
  background-color: #f5f5f5; 
}

.custom-table .table-row-white {
  background-color: #ffffff; 
}

.icon-btn-style {
  margin-right: 8px;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  opacity: .7;
  background-color: $Orange;
  color: #fff;
  cursor: not-allowed !important;
}

#auditTrailTable {
  thead tr th {
    background: $containerBackground !important;
    padding: 5px 15px !important;
    border: 0px solid red ;
  }
  tbody tr td {
    padding: 15px !important;
  }
}

.table-spinner-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  // background-color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-footer{
  padding: 20px !important;
}

.login-page-lg {
  background-image: $loginImageLargeScreen;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh
}

.usermaster-card::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
  border-radius: 20px;
}

.usermaster-card::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

.usermaster-card::-webkit-scrollbar-thumb {
  background: $sidebarScroll;
  border-radius: 20px;
}

.usermaster-card::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .MuiTreeItem-content .MuiTreeItem-label {
    font-size: calc(var(--cui-body-font-size) * 0.9) !important;
  }
}


.permpage{
  height: 80vh;
  align-items: center;
  display: grid;
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper{
  width: 0px !important;
}

.accessrights-scroll::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

.accessrights-scroll::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}

.accessrights-scroll::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}

.accessrights-scroll::-webkit-scrollbar-thumb {
  background: $scrollbarOrange;
  border-radius: 20px;
}

.pagination-dropdown {
  width: 75px;
  height: 32px;
  background-size: 0.7em;
  font-size: 0.9rem;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .pagination-dropdown {
      height: 25px;
      min-width: 25px;
      font-size: calc(var(--cui-body-font-size)* 0.75);
      line-height: 1;
  }

  h5 {
    font-size: calc(var(--cui-body-font-size)* 0.9);
  }
}

@media (max-width: 768px) {

  h5 {
    font-size: calc(var(--cui-body-font-size)* 0.9);
  }
}

.sidebar-nav .nav-group-items .nav-link{
  white-space: normal;
}

@media (max-width: 2560px) {

  .pagination-dropdown {
    height: 25px;
    min-width: 25px;
    font-size: calc(var(--cui-body-font-size)* 0.75);
    line-height: 1;
}
}

.propertyDetails-mini-table {
  background: $tableContainerBackground;
  border: $tableContainerBackground;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  margin: 10px;
}

.jodit .jodit-workplace .jodit-wysiwyg, 
.jodit .jodit-workplace .jodit-wysiwyg_iframe,
.jodit-container .jodit-workplace .jodit-wysiwyg, 
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe {
  // height: 410px !important;
  height: 500px !important;
}

.jodit-wysiwyg {
  min-height: 100% !important;
}

.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  display: none;
}


@media (max-width: 1024px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 67vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }

  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 375px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 62vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }
  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 300px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 55vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }
  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 425px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 76vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }
  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 1440px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 72vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }
  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 3000px) {
  // .form-card-body {
  //   background-color: $formContainerCardBody;
  //   height: 84vh;
  //   overflow-y: auto;
  // }
  .report-card-body {
    background-color: $formContainerCardBody;
    height: 80vh;
    overflow-y: auto;
  }
  .table-card-body {
    background-color: $tableContainerBackground;
    border-radius: $tableContainerBorderRadius;
    height: 100%;
    overflow-y: scroll;
  }
}

.form-card-body::-webkit-scrollbar-track,
.table-card-body::-webkit-scrollbar-track,
.report-card-body::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}
.form-card-body::-webkit-scrollbar,
.table-card-body::-webkit-scrollbar,
.report-card-body::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

.form-card-body::-webkit-scrollbar-track,
.table-card-body::-webkit-scrollbar-track,
.report-card-body::-webkit-scrollbar-track {
  // background: #e3e3fc;
  background: $scrolbarTrackOrange;
}

.form-card-body::-webkit-scrollbar-thumb:hover,
.table-card-body::-webkit-scrollbar-thumb:hover,
.report-card-body::-webkit-scrollbar-thumb:hover {
  background: $Orange;
}

.form-card-body::-webkit-scrollbar-thumb,
.table-card-body::-webkit-scrollbar-thumb,
.report-card-body::-webkit-scrollbar-thumb {
  background: $scrollbarOrange;
  border-radius: 20px;
}


.MuiCollapse-root {
  background-color: white;
  border-radius: 4px;
}

.tooltip {
  position: fixed;
}

input::placeholder {
  // color: #9e9d9d; /* Replace with your desired color */
  // opacity: 1; /* Optional: adjust opacity if needed */
  font-family: 'PlusJakartaSans', sans-serif; /* Ensure to provide a fallback */
  font-size: 1rem;
}

// .MuiInputBase-input {
//   font-family: 'PlusJakartaSans';
// }

.MuiOutlinedInput-root .MuiInputBase-input {
  font-family: 'PlusJakartaSans', sans-serif; /* Ensure to provide a fallback */
  font-size: 1rem;
}

// .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
//   background-color: var(--cui-secondary-bg);
// }

@media (min-width: 300px) and (max-width: 1100px) {
  .unit-masbtn{
    width: 80px;
    height: 38px;
  }

  .btn-orange {
    width: 80px;
    height: 38px;
  }
}


// .MuiOutlinedInput-input {
//   &::placeholder {
//       color: black;
//   }

//   &.Mui-disabled {
//     -webkit-text-fill-color: black;
//       &::placeholder {
//           -webkit-text-fill-color: black;
//       }
//   }
// }

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: var(--cui-body-color) !important;
  color: var(--cui-body-color) !important;
  z-index: 200;
}

.MuiAutocomplete-root.MuiOutlinedInput-root.Mui-disabled fieldset {
  background-color: var(--cui-secondary-bg);
}


// .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
//   -webkit-text-fill-color: var(--cui-body-color) !important;

// }

.MuiAutocomplete-root .MuiOutlinedInput-root.Mui-disabled fieldset {
  background-color: var(--cui-secondary-bg);
}

.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  z-index: 100;
}

.MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background-color: var(--cui-secondary-bg);
}


.MuiButtonBase-root .MuiChip-root.Mui-disabled {
  opacity: 1 !important;

}

.MuiAutocomplete-root .MuiAutocomplete-tag {
  z-index: 200;
  opacity: 1 !important;
}

.remove-border canvas {
  display: none !important;
}
.invoice-fields-background {
  border: 1px solid #E6E8E7;
  padding-bottom: 25px;
  background-color: $containerBackground;
  display: flex;
  flex-direction: column-reverse;
}

.form-switch-css {
  padding-left: 0;
}

.dot-circle-red {
  height: 15px;
  width: 15px;
  background-color: #EA4335;
  border-radius: 50%;
  display: inline-block;
}

.dot-circle-green {
  height: 15px;
  width: 15px;
  background-color: #34A853;
  border-radius: 50%;
  display: inline-block;
}

.dot-circle-grey {
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
}

.dot-circle-empty {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #ccc;
  display: inline-block;
}


.btn:active {
  border-color: none !important;
  border: none !important;
}

.form-check-input:disabled {
  background-color: var(--cui-secondary-bg);
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  cursor: pointer;
}

.fc-h-event {
  background-color: none !important;
}

.fc .fc-daygrid-day-frame:hover {
  background-color: $containerBackground;
}

.fc {
  background-color: none;
}

.days-checkbox-wrapper {
  display: flex;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: $containerBackground;
}

.view_images_link {
    color: rgba(88, 86, 214, 1);
    cursor: pointer;
    border: none;
}

.checklist-background {
  padding: 10px;
  background-color: $tableContainerBackground;
  border-radius: 1rem;
}

.yesformcheck{
  .form-check-input:checked {
    background-color: #4ef364;
    border-color: #4ef364;
}
}

.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}


.lead_qualify_error {
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 2px 4px;
    border-radius: 4px;
}

.lead_qualify_success {
  color: black;
  background-color: none;
  padding: 2px 4px;
  border-radius: 4px;
}

// .jodit-dialog__panel {
//   width: 80vw !important;
//   height: 80vh !important;
//   left: 5rem !important;
//   top: 5rem !important;
// }

.jodit__preview-box.jodit-context {
  height: 100% !important;
  
}

.jodit__preview-box.jodit-context iframe {
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
}
